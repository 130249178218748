.App {
  text-align: center;
}

.App-logo {
  height: 120px;
  pointer-events: none;
}

.App-body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.controls select,
.controls input,
.controls button {
  margin: 0 12px;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.controls button {
  background-color: #2dce89;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.controls button:hover {
  background-color: #1d885a;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.hidden {
  display: none;
}

/* Tambahkan animasi loading */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

/* Gaya untuk area drop file */
.file-drop {
  width: 550px;
  height: 200px;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  transition: border-color 0.3s, background-color 0.3s;
}

/* Gaya teks di dalam area drop file */
.file-drop p {
  margin: 0;
  font-size: 16px;
  color: #555;
}

/* Efek animasi ketika file dijatuhkan */
.file-drop.active {
  border-color: #007bff;
  background-color: #e9f5ff; /* Warna latar belakang yang berubah saat file dijatuhkan */
}

/* Efek animasi ketika file dijatuhkan */
.file-drop p.active {
  color: #007bff;
}

/* Icon untuk menampilkan ketika file dijatuhkan */
.file-drop .icon {
  font-size: 40px;
  color: #007bff;
}

.file-drop img {
  max-width: 50px; /* Membatasi lebar maksimal gambar */
  max-height: 50px; /* Membatasi tinggi maksimal gambar */
}

.file-drop button{
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 10px;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.file-drop button:hover {
  background-color: #258e3e;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.tutorial {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.tutorial p{
  font-size: 16px;
  font-weight: bold;
  color: #FFFF00;
}
